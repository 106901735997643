import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios";
import service from "./service/index";
import vuetify from "./plugins/vuetify";
import style from "./style/style.scss";
import validations from "@/utils/validations";
import VueNumeric from "vue-numeric-currency";
import vueDebounce from "vue-debounce";
import VueTheMask from "vue-the-mask";
import global from "./store/global";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import uicons from '../public/uicons-regular-rounded/css/uicons-regular-rounded.css'
import './utils/filters';


import {
  uniAnalysis,
  uni12Plus,
  uni13Plus,
  uni6Plus,
  uniHospitalMonochrome,
} from "vue-unicons/dist/icons";
import VueBarcodeScanner from "vue-barcode-scanner";
import VueQRCodeComponent from "vue-qrcode-component";
import VueHtmlToPaper from "vue-html-to-paper";
import FlowyPlugin from "@hipsjs/flowy-vue";
import utils from './utils/index'
import 'devextreme/dist/css/dx.light.css';
import "scanner-js";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";
import DemoNode from "./views/organizations/DemoNode";
import Field from "./views/workflow/nodes/field";
import i18n from './i18n'
const options1 = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    // "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    // "https://unpkg.com/kidlat-css/css/kidlat.css",
    "https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js",
    "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
    "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",

  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};
import Print from 'vue-print-nb'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const optionsT = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
};


Vue.use(Toast, optionsT);
// Global instruction 
Vue.use(Print);

Vue.use(VueHtmlToPaper, options1);
Vue.use(FlowyPlugin);
// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);
Vue.component("qr-code", VueQRCodeComponent);
Vue.component("demo-node", DemoNode);
Vue.component("field", Field);

let options = {
  sound: true, // default is false
  soundSrc: "/static/sound.wav", // default is blank
  sensitivity: 300, // default is 100
  requiredAttr: true, // default is false
  controlSequenceKeys: ["NumLock", "Clear"], // default is null
  callbackAfterTimeout: true, // default is false
};

Vue.use(VueBarcodeScanner, options);

Vue.component("LottieAnimation", () => import("lottie-web-vue"));
Unicon.add([
  uniAnalysis,
  uni12Plus,
  uni13Plus,
  uni6Plus,
  uniHospitalMonochrome,
]);
Vue.use(Unicon);

Vue.use(VueTheMask);
Vue.prototype.$phoneMask = "+964 ### ### ####";
Vue.prototype.$birthDate = "####/##/##";
Vue.prototype.$money = "#,###.##";
Vue.prototype.$utils = utils;
Vue.use(vueDebounce);
Vue.prototype.$service = service;
Vue.use(VueNumeric);
Vue.prototype.$http = axios;
Vue.prototype.$validations = validations;
Vue.prototype.$global = global;

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.component("empty", () => import("./components/Empty.vue"));
Vue.component("Loading", () => import("./components/Loading.vue"));
Vue.component("Pagination", () => import("./components/Pagination.vue"));

//401
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      if (error.response.status == 401) {
        router.replace({
          path: "/login",
          query: { redirect: router.currentRoute.fullPath },
        });
      }
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);
//401

async function init() {
    try {
      const response = await fetch('/config.json');
      const config = await response.json();
      axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? config.BASE_URL_PROD +'/api/' : config.BASE_URL_DEV +'/api/' ; 
      Vue.prototype.$config = config
      new Vue({
        router,
        store,
        vuetify,
        style,
        i18n,
        uicons,
      render: (h) => h(App),
      }).$mount('#app');
    } catch (error) {
      console.error('Error loading config.json:', error);
    }
  }
  
  init();

