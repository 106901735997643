var i18n = require('../i18n').default;
const axios = require("axios");
const EventEmitter = require("events");
const eventBus = new EventEmitter();
const numeral = require("numeral");
const store = require('../store/index').default;
const config = require('../../public/config.json')
var url = process.env.NODE_ENV == 'production' ? config.BASE_URL_PROD : config.BASE_URL_DEV  ; 
const moment = require('moment');
var warningText = i18n.t('hl-ant-mtakd-mn-htha-alijraa');
var successText = i18n.t('tm-alhthf-bnjah');

function deleteItem(id, api, code, myWarningText, mySuccessText) {
  eventBus.emit("confirm", {
    id: id,
    text: myWarningText == undefined ? warningText : myWarningText,
    code: code,
  });


  eventBus.once(code, (data1) => {
    axios({
      url: url + "/api/" + api + "/" + id,
      method: "delete",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      // data: {id: id}
    })
      .then((res) => {
        eventBus.emit("send-data", {
          type: "done",
          text: mySuccessText == undefined ? successText : mySuccessText,
        });
      })
      .catch((err) => {
        eventBus.emit("send-data", {
          type: "error",
          text: i18n.t('fshlt-alamlyh'),
        });
      });
  });
}

async function activeItem(id, api, code, myWarningText, mySuccessText) {
  console.log(api)

  await eventBus.emit("confirm", {
    id: id,
    text: myWarningText == undefined ? warningText : myWarningText,
    code: code,
  });

  await eventBus.once(code, (data1) => {
    axios({
      url: url + "/api/" + api + "/" + id,
      method: "put",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      // data: {id: id}
    })
      .then((res) => {
        eventBus.emit("send-data", {
          type: "done",
          text: mySuccessText == undefined ? successText : mySuccessText,
        });
      })
      .catch((err) => {
        console.log(err)
        eventBus.emit("send-data", {
          type: "error",
          text: i18n.t('fshlt-alamlyh'),
        });
      });
  });
}

var successText2 = i18n.t('tmt-alamlyh-bnjah');
var faildText = i18n.t('hdth-khta');

function faild(myText) {
  eventBus.emit("send-data", {
    type: "error",
    text: myText == undefined ? faildText : myText,
  });
}

function success(myText) {
  eventBus.emit("send-data", {
    type: "done",
    text: myText == undefined ? successText2 : myText,
  });
}

function formatCurrency(x) {
  return numeral(x).format("0,0");
}

function refresh(key) {
  console.log(key);
  eventBus.emit(key);
}
function formatDate(date, showTime = true, short = false) {
  showTime;
  var formate = "YYYY/MM/DD";
  if (showTime) {
    formate = "YYYY/MM/DD hh:mmA";
  }
  if (short) {
    formate = "MM/DD";
  }
  var ret = moment(date).format(formate);
  return ret;
}
module.exports = {
  url: url,
  deleteItem: deleteItem,
  eventBus: eventBus,
  faild: faild,
  success: success,
  formatCurrency: formatCurrency,
  refresh: refresh,
  activeItem: activeItem,
  formatDate: formatDate,
};
