import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import global from "../store/global";
import routesModel from './routes'
import users from './routes/users'
import organizations from './routes/organizations'
import workflow from './routes/workflow'
import emails from './routes/emails'
Vue.use(VueRouter);

const routes = [
  ...routesModel,
  ...users,
  ...organizations,
  ...workflow,
  ...emails
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  global.dispatch("reset");
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (store.state.token == undefined || store.state.token == null) {
      next("/login");
    }
    if(to.meta.auth.find(x => x == store.state.user.role) == undefined){
      next("/404");
    }
    next();
  }
  next();
});

export default router;