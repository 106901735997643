import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios";
import service from "../service/index";
import validations from "@/utils/validations";
import store from "./index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...validations,
    loading: false,
    uploading: false,
    path: "",
    justNumbers: "return (event.charCode >= 48 && event.charCode <= 57)",

    filter: {
      pageIndex: 1,
      pageSize: 50,
      search: "",
      IsDeleted: "false",
    },
    workflowId: "",

    length: 0,
    errors: [],
    errorMessage: "",
    organizations: [],
    documentCategory: [],
    users: [],
    fields: [],
    ministries: [],
    fieldsTypes: [],
    workflows: [],
    emails: [],
    workflow: {},
    mainNodes: [],
    nodes: [],
    organizationTypes: [],
    orders: [],
    documents: [],

    guestStatus: [
      {
        text: "بأنتظار الموافقة",
        id: "8605ae2f-ae5e-4dd9-81c0-113e82b5e166"
      },
      {
        text: "جار المعالجة",
        id: "203772c4-ad81-40a0-be50-d054b9b9dc8c"
      },
      {
        text: "مكتمل",
        id: "1423200a-acbd-4011-83bc-7ef289d9aa51"
      },
      {
        text: "منتهي المدة",
        id: "2338abd9-b310-4b8b-8e64-c14730fce3b1"
      },
      {
        text: "مرفوض",
        id: "2ecdb56d-6b73-45ef-af3f-bf4902af5725"
      },
    ],
    status: [
      {
        text: "الكل",
        name: "الكل",
        id: null,
        key: null,
        color: null
      },
      {
        text: "بأنتظار الموافقة",
        name: "بأنتظار الموافقة",
        id: "8605ae2f-ae5e-4dd9-81c0-113e82b5e166",
        key: "waitAccept",
        color: null
      },
      {
        text: "بأنتظار ملئ البيانات",
        name: "بأنتظار ملئ البيانات",
        id: "b4a58428-38eb-4663-a16f-7d4ad0020ee1",
        key: "waitData",
        color: null
      },
      {
        id: "203772c4-ad81-40a0-be50-d054b9b9dc8c",
        name: "جار المعالجة",
        key: "inprogress",
        color: null,
      },
      {
        id: "2ecdb56d-6b73-45ef-af3f-bf4902af5725",
        name: "مرفوض",
        key: "rejected",
        color: null,
      },
      {
        id: "1423200a-acbd-4011-83bc-7ef289d9aa51",
        name: "مكتمل",
        key: "completed",
        color: null,
      },
      {
        id: "2338abd9-b310-4b8b-8e64-c14730fce3b1",
        name: "منتهي الصلاحية",
        key: "expired",
        color: null,
      },
    ],

    roles: [
      // {
      //   id: "1fa1ba64-65c3-4a5a-8077-652381807ec9",
      //   name: "Admin",
      //   name_ar: "مدير النظام",
      // },
      {
        id: "5ed22c1d-4660-4503-968d-90b6054ee51c",
        name: "User Managmenet",
        name_ar: "مدير مستخدمين",
      },
      {
        id: "44baa644-642f-48b1-b6ba-b8b3674efccf",
        name: "Manager",
        name_ar: "مدير",
      },
      {
        id: "79b07643-38b7-4e61-9dbc-109078d8dce5",
        name: "Employee",
        name_ar: "موظف",
      },
      {
        id: "c2414803-eb0d-469a-870e-ff88dcee3fcb",
        name: "Guest",
        name_ar: "ضيف",
      },
      {
        id: "c37c0a8b-9e99-4b64-b728-dc9c5bdba8cd",
        name: "Security",
        name_ar: "قسم التدقيق الأمني",
      },
      {
        id: "7ae761bb-c3ff-4644-93e2-cecbcd1923a1",
        name: "Gate",
        name_ar: "حماية البوابات",
      },
      {
        id: "cf0b2e18-fa8b-4548-a711-fc05f6a214ec",
        name: "Benefit",
        name_ar: "جهة مستفيدة",
      },
      {
        "id": "416568bd-cc90-4c66-914e-bbaf77d6d11c",
        "name": "Outcoming",
        name_ar: "صادر ",
      },
      {
        "id": "e826904c-643c-4fe3-9dc2-f89d4a72a4d3",
        "name": "Incoming",
        name_ar: "وارد ",
      },
    ],

    roles_operation: [
      // {
      //   "id": "79b07643-38b7-4e61-9dbc-109078d8dce5",
      //   "name": "Employee",
      //   "name_ar": "موظف",
      // },
      // {
      //   "id": "c2414803-eb0d-469a-870e-ff88dcee3fcb",
      //   "name": "Guest",
      //   "name_ar": "ضيف",
      // },
      {
        id: "c37c0a8b-9e99-4b64-b728-dc9c5bdba8cd",
        name: "Security",
        name_ar: "قسم التدقيق الأمني",
      },
      {
        id: "7ae761bb-c3ff-4644-93e2-cecbcd1923a1",
        name: "Gate",
        name_ar: "حماية البوابات",
      },
      {
        id: "cf0b2e18-fa8b-4548-a711-fc05f6a214ec",
        name: "Benefit",
        name_ar: "جهة مستفيدة",
      },
    ],

    provinces: [
      {
        id: "eca06279-1573-4efc-8569-d2ed57dd3aa3",
        name: "أربيل",
      },
      {
        id: "34457f86-f6b9-485a-b0c4-14deac310af6",
        name: "الانبار",
      },
      {
        id: "39694e56-9f47-46b3-bb49-5405643886ce",
        name: "بابل",
      },
      {
        id: "24e45ff2-e357-4ff3-b2d9-755099c6986c",
        name: "بغداد",
      },
      {
        id: "9802d49c-ba90-4b77-81d4-0f27e0a7ea4f",
        name: "البصره",
      },
      {
        id: "ee24b32c-a036-436b-92f1-bb72f6e41687",
        name: "دهوك",
      },
      {
        id: "6cb794fd-60e3-42c0-a31f-f6b111bdb831",
        name: "النجف",
      },
      {
        id: "8b7f8d06-9a5d-4f2f-a538-2149c5e600de",
        name: "الديوانيه",
      },
      {
        id: "73047736-41dc-4a48-ab00-d1746dab8a6a",
        name: "ديالى",
      },
      {
        id: "4b8b52e1-9f4c-48ee-b9b1-6821eab0b55f",
        name: "ذي قار",
      },
      {
        id: "f2f50b10-900f-4016-b6d3-58cae498510a",
        name: "السليمانيه",
      },
      {
        id: "7f2e28f5-7690-4241-80b4-db3339737dad",
        name: "صلاح الدين",
      },
      {
        id: "d86a2058-8ab4-4cbc-b5c7-032eca1487a4",
        name: "كركوك",
      },
      {
        id: "8295ffe3-7ceb-454b-a2fe-11992159c26a",
        name: "كربلاء",
      },
      {
        id: "6300f406-cbda-4dff-82d2-84f772895027",
        name: "المثنى",
      },
      {
        id: "6b95d0d6-7c7f-4ca2-af77-268da1f5052f",
        name: "ميسان",
      },
      {
        id: "786b996d-316a-4299-bd1a-873e46a51e36",
        name: "نينوى",
      },
      {
        id: "c0324a5b-8b95-44cb-afee-a9d9a99f3f7e",
        name: "واسط",
      },
    ],
    groups: [],
  },

  mutations: {
    setWorkflow(state, workflow) {
      state.workflow = workflow;
    },
  },

  actions: {
    reset() {
      this.state.filter.search = "";
      this.state.provinceId = "";
      this.state.regionId = "";
      this.state.paymentTypeId = "";
      this.state.guaranteesId = "";
      this.state.statusId = "";
      this.state.saleId = "";
    },

    async handleFileUpload(context, { file, formDataName, endPoint }) {
      this.state.uploading = true;
      try {
        let formData = new FormData();
        // formData.append('files', file);
        formData.append(formDataName, file);
        let res = await axios.post(endPoint, formData);
        formDataName == "files"
          ? (this.state.path = res.data.fileList[0].path)
          : "";
        service.success("تم رفع الملف بنجاح");
        // service.refresh('refresh')
        console.log(Vue);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.uploading = false;
      }
    },
    async getEmail(state, role = "") {
      this.state.loading = true;
      try {
        const res = await axios.get(`Email`, {
          params: this.state.filter,
        });
        this.state.emails = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getOrganization(state, role = "") {
      this.state.loading = true;
      try {
        const res = await axios.get(`Organization`, {
          params: {
            pageIndex: 1,
            IsDeleted: false,
            pageSize: 100,
          },
        });
        this.state.organizations = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getOrganizationType(state, role = "") {
      this.state.loading = true;
      try {
        const res = await axios.get(`OrganizationType`, {
          params: this.state.filter,
        });
        this.state.organizationTypes = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getDocument(state, params = {}) {
      this.state.loading = true;
      try {
        let filter = {
          ...this.state.filter,
          ...params,
        };

        const res = await axios.get(`Document`, { params: filter });
        // console.log(res.data.result.slice(res.data.result.indexOf(res.data.result.find(x => x.fullName == 'admin')),1))

        this.state.documents = res.data.result;
        for (let index = 0; index < this.state.documents.length; index++) {
          this.state.documents[index].isSelected = false;
        }
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) { 
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getUser(state, params = {}) {
      this.state.loading = true;
      try {
        let filter = {
          ...this.state.filter,
          ...params,
        };

        const res = await axios.get(`User`, { params: filter });
        // console.log(res.data.result.slice(res.data.result.indexOf(res.data.result.find(x => x.fullName == 'admin')),1))
        // res.data.result.splice(
        //   res.data.result.findIndex((x) => x.userName == "admin"),
        //   1
        // );
        this.state.users = res.data.result;
        this.state.users = this.state.users.filter(v => v.role.name != "Admin");
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getGroup(state) {
      this.state.loading = true;
      try {
        const res = await axios.get(`Group`, { params: this.state.filter });
        this.state.groups = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getMinistry(state, { search = null }) {
      this.state.loading = true;
      try {
        const res = await axios.get(`Ministry`, {
          params: {
            Name: search,
            PageIndex: 1,
            PageSize: 1000
          }
        });
        this.state.ministries = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getDocumentCategory(state, { search = null }) {
      this.state.loading = true;
      try {
        const res = await axios.get(`DocumentCategory`, {
          params: {
            Name: search,
            pageSize: this.state.filter.pageSize,
            pageIndex: this.state.filter.pageIndex
          }
        });
        this.state.documentCategory = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getField(state, params = {}) {
      this.state.loading = true;
      let filter = {
        ...this.state.filter,
        ...params,
      };
      try {
        const res = await axios.get(`Field`, { params: filter });
        this.state.fields = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getFieldType(state) {
      this.state.loading = true;
      try {
        const res = await axios.get(`Field/types`, {
          params: this.state.filter,
        });
        this.state.fieldsTypes = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },

    async getWorkFlow(state, params = {}) {
      this.state.loading = true;
      try {
        // let GroupIds = [];
        // if (store.state.user.user.userGroups.length > 0) {
        //   await store.state.user.user.userGroups.forEach((element) => {
        //     GroupIds.push(element.groupId);
        //   });
        // }
        // let url = `Order?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&StatusId=${this.state.status.find((x) => x.key == "inprogress").id}&Name=${this.state.filter.search}&IsDelete=false&Sort=desc&UserId=${ store.state.user.id}&WorkflowId=${this.state.workflowId == null ? "" : this.state.workflowId}`
        // for (let j = 0; j < GroupIds.length; j++) {
        //     url = url + `&GroupIds=${ GroupIds[j] }`;
        // }
        let filter = {
          ...this.state.filter,
          ...params,
        };

        const res = await axios.get(
          `WorkFlow?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&Search=${this.state.filter.search}&IsDelete=false`
        );
        this.state.workflows = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },

    async getOrder() {
      this.state.loading = true;
      try {
        let GroupIds = [];
        if (store.state.user.user.userGroups.length > 0) {
          await store.state.user.user.userGroups.forEach((element) => {
            GroupIds.push(element.groupId);
          });
        }
        let url = `Order?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize
          }&StatusId=${this.state.status.find((x) => x.key == "inprogress").id
          }&Name=${this.state.filter.search}&IsDelete=false&Sort=desc&UserId=${store.state.user.id
          }&WorkflowId=${this.state.workflowId == null ? "" : this.state.workflowId
          }`;
        for (let j = 0; j < GroupIds.length; j++) {
          url = url + `&GroupIds=${GroupIds[j]}`;
        }
        const res = await axios.get(url);
        this.state.orders = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },

    async getMainNode(state, WorkflowId = null) {
      this.state.loading = true;
      try {
        const res = await axios.get(
          `MainNode?PageIndex=${this.state.filter.pageIndex}&PageSize=${this.state.filter.pageSize}&IsDelete=false&WorkflowId=${WorkflowId}`
        );
        this.state.mainNodes = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getNodes(state, WorkflowId = null) {
      this.state.loading = true;
      this.state.filter.WorkflowId = WorkflowId;
      try {
        const res = await axios.get(`node`, {
          params: this.state.filter,
        });
        this.state.nodes = res.data.result;
        this.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
    async getActions(state, WorkflowId = null) {
      this.state.loading = true;
      this.state.filter.MainNodeId = WorkflowId;
      try {
        const res = await axios.get(`Action`, {
          params: this.state.filter,
        });
        this.state.actions = res.data.result;
        // this.state.length =
        //   res.data.count == undefined
        //     ? 0
        //     : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        service.faild(err.response.data.message);
      } finally {
        this.state.loading = false;
      }
    },
  },
  modules: {},
});
