import axios from 'axios'
import service from '../service'
import store from '../store'
axios.get('/config.json').then(res => {
  store.commit('setConfig', res.data)
  axios.defaults.baseURL = (process.env.NODE_ENV == "production" ? store.state.config.PRODUCTION_API : store.state.config.DEVELOPMENT_API) + '/api/'
})
console.log(store.state.config);
export default axios.create({
  baseURL: (process.env.NODE_ENV == "production" ? store.state.config.PRODUCTION_API : store.state.config.DEVELOPMENT_API) + '/api/',
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${sessionStorage.getItem('token')}`
      }
    },
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
})