<template>
  <div>
    <v-card flat outlined class="my-card bg-white pa-2" style="direction: rtl">
      <v-card-title class="row items-center no-wrap">
        <div class="text-h6">{{ title }}</div>
        <v-spacer></v-spacer>

        <flowy-drag-handle>
          <v-btn size="sm" color="primary" rounded text icon>
            <v-icon>mdi-drag</v-icon>
          </v-btn>
        </flowy-drag-handle>
      </v-card-title>
      <v-card-text>
        <div class="py-md-2" v-html="description" />
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn @click="openAddDialog(item)" v-on="on" text color="primary">
              <v-icon> add </v-icon>
            </v-btn>
          </template>
          <span>اضافة منظمة فرعية</span>
        </v-tooltip>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" @click="openEditDialog(item)" v-on="on" icon>
              <v-icon color="info"> edit </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit') }}</span>
        </v-tooltip>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn @click="deleteItem(item.id)" v-on="on" icon>
              <v-icon color="error"> delete </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('hthf') }}</span>
        </v-tooltip>
      </v-card-text>
    </v-card>

    <!-- <Dialog /> -->
  </div>
</template>
<script>
// import Dialog from "./dialog";
export default {
  components: {
    // Dialog,
  },

  data() {
    return {
      text: "This is component A",
      item: {},
    };
  },
  mounted() {
    this.reset();

    this.$eventBus.$on("fill-fields", () => {
      console.log(this.$store.state.itemDetails);
      this.item = this.$store.state.itemDetails;
    });
  },
  methods: {
    reset() {},
    openAddDialog() {
      var item = {
        dialogType: "add",
        parentId: this.node.id,
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },
    deleteItem(id, i) {
      this.$service.deleteItem(id, `${this.$route.meta.endPoint}`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        this.$eventBus.$emit(`refresh`);
      });
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  props: ["remove", "node", "title", "description"],
  created() {
    console.log(this.node);
    this.item = {
      id: this.node.id,
      name: this.node.data.title,
      level: 0,
      parentId: this.node.parentId == -1 ? null : this.node.parentId,
      organizationTypeId: this.node.organizationTypeId,
      userId: this.node.userId,
    };
  },
};
</script>
