import global from "../../store/global";

var i18n = require("../../i18n").default;
let t = (key) => i18n.t(key);
export default [
  {
    path: "/organizations",
    name: t('almnzmat'),
    component: () => import("../../views/organizations/organizations.vue"),
    meta: {
      single: t('mnzmh'),
      endPoint: "Organization",
      breadcrumbs: [{ text: t('almnzmat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","User Managmenet", "Manager"],
    },
  },
  {
    path: "/organizationss",
    name: t('almnzmat'),
    component: () => import("../../views/organizations/organizations1.vue"),
    meta: {
      single: t('mnzmh'),
      endPoint: "Organization",
      breadcrumbs: [{ text: t('almnzmat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      hideDrawer: true,
      hideNavigation: true,
      auth: ["Admin","User Managmenet", "Manager"],
    },
  },
  {
    path: "/organizationsTypes",
    name: t('anwaa-almnzmat-0'),
    component: () => import("../../views/organizations/types/types.vue"),
    meta: {
      single: "نوع",
      endPoint: "OrganizationType",
      breadcrumbs: [{ text: t('anwaa-almnzmat-0'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","User Managmenet", "Manager"],
    },
  },
];
