<template>
  <v-app>
    <div class="area">
      <v-row justify="center" align="center" style="height: 100%">
        <v-col class="text-center" cols="12">
          <!-- <v-avatar size="100">
                    <v-img src="@/assets/images/system logo.jpg"></v-img>
                </v-avatar> -->
          <v-divider vertical="" inset="" class="mx-2" />

          <v-card
            outlined
            class="mx-auto text-center"
            width="400px"
            style="z-index: 2"
          >
            <v-container>
              <v-avatar size="150" tile>
                <v-container>
                  <!-- <v-img :src="$config.logo" /> -->
                  <v-img :src="$config.logo" />
                </v-container>
              </v-avatar>
              <!-- <v-avatar size="200" rounded="">
                <v-container>
                  <v-img :src="$store.state.config.logo" />
                </v-container>
              </v-avatar> -->
              <h2 class="mt-2" style="color: #122940">
                {{ $config.title }}
              </h2>
              <h2 class="mt-2" style="color: #122940">
                منظومة سير العمل الإداري
              </h2>
              <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
              <br />
              <v-form @submit.prevent="submit" ref="form" v-model="valid">
                <v-text-field
                  v-model="user.userName"
                  filled
                  prefix=" | "
                  :label="$t('username')"
                  outlined
                  :rules="[required()]"
                >
                  <template v-slot:prepend-inner>
                    <user-icon />
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="user.password"
                  prefix=" | "
                  filled
                  :label="$t('password')"
                  dir="ltr"
                  outlined
                  :rules="[required()]"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                >
                  <template v-slot:prepend-inner>
                    <lock-icon />
                  </template>
                </v-text-field>
                <v-btn
                  class="btn"
                  :loading="loading"
                  type="submit"
                  color="primary"
                  x-large
                  block
                >
                  {{ $t("login") }}
                </v-btn>
              </v-form>
              <!-- <v-btn text class="mt-4" color="primary" to="/register">
                <h3>
                  {{ $t("tsjyl-hsab-jdyd-k-dhyf") }}
                </h3>
              </v-btn> -->
            </v-container>
          </v-card>
        </v-col>

        <v-col>
          <!-- <div>
                    <v-col class="text-center">
                        <loginSupport />
                    </v-col>
                </div> -->
          <v-row justify="center">
            <span style="color: #7c7c7c">
              {{ $t("tm-alttwyr-bwasth") }}
              <a href="https://www.google.com/">
                {{ $config.company }}
              </a>
              |
              {{ new Date().getFullYear() }}
            </span>
          </v-row>
        </v-col>
      </v-row>

      <!-- <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul> -->
    </div>
  </v-app>
</template>

<script>
import validations from "@/utils/validations";
import { UserIcon, LockIcon } from "vue-feather-icons";
export default {
  components: {
    UserIcon,
    LockIcon,
    // loginSupport: () => import('../../assets/animation/loginSupport.vue'),
  },

  data() {
    return {
      user: {},
      loading: false,
      process: {},
      valid: true,
      showPassword: false,
      ...validations,
    };
  },

  created() {
    this.process = process.env;
    // console.log(process);
    this.user = {
      userName: "",
      password: "",
      clientId: this.generateUUID(),
      clientType: "web",
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const res = await this.$http.post("/Auth/login", this.user);
        await sessionStorage.setItem("token", res.data.result.token);

        await sessionStorage.setItem("user", JSON.stringify(res.data.result));
        await this.$store.dispatch("login");
        switch (res.data.result.role) {
          case "Employee":
            await this.$router.push("/orders");
            break;

          case "Manager":
            await this.$router.push("/orders");
            break;

          case "Benefit":
            await this.$router.push("/permit");
            break;

          case "Security":
            await this.$router.push("/permit");
            break;

          case "Gate":
            await this.$router.push("/gate");
            break;

          case "Admin":
            await this.$router.push("/users");
            break;

          case "User Managmenet":
            await this.$router.push("/users");
            break;
          case "Guest":
            await this.$router.push("/guest");
            break;
          case "User":
            await this.$router.push("/orderDataChild");
            break;
          case "Outcoming":
            await this.$router.push("/documentsList/outgoing");
            break;
          case "Incoming":
            await this.$router.push("/documentsList/incoming");
            break;
          default:
            await this.$router.push("/users");
            break;
        }
      } catch (err) {
        this.$service.faild("إسم المستخدم او كلمة المرور خطأ");
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
  },
};
</script>

<style>
.v-text-field__slot {
  /* padding: 15px !important; */
}
</style>
<style lang="scss">
// @import url('https://fonts.googleapis.com/css?family=Exo:400,700');

// * {
//   margin: 0px;
//   padding: 0px;
// }

// body {
//     font-family: 'Exo', sans-serif;
// }

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: #ffffff;
  background-size: 50% auto;
  background: white;
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

#background {
  position: fixed;
  top: 0;
  // left: 0;
  width: 50%;
  height: 100%;
  background-color: #4460a3;
}

#content {
  position: relative;
  z-index: 2;
  padding: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
