import global from "../../store/global";

var i18n = require("../../i18n").default;
let t = (key) => i18n.t(key);
export default [
  {
    path: "/users",
    name: t('almstkhdmyn'),
    component: () => import("../../views/users/shared/users.vue"),
    meta: {
      single: t('mstkhdm'),
      endPoint: "User",
      breadcrumbs: [{ text: t('almstkhdmyn'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","User Managmenet", "Manager"],
    },
  },
  {
    path: "/users/groups",
    name: t('almjmwaat'),
    component: () => import("../../views/users/groups/groups.vue"),
    meta: {
      single: t('mjmwah'),
      endPoint: "Group",
      breadcrumbs: [{ text: t('almjmwaat'), disabled: true }],
      // requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","User Managmenet", "Manager"],
    },
  },
];
