<template>
  <v-card outlined>
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <flowy-drag-handle>
        <v-btn color="primary"   text>
          <v-icon>mdi-drag</v-icon>
        </v-btn>
      </flowy-drag-handle>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: ["title"],
};
</script>
