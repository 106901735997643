import global from "../../store/global";
var i18n = require("../../i18n").default;
let t = (key) => i18n.t(key);
export default [
  {
    path: "/fields",
    name: t('alhqwl'),
    component: () => import("../../views/fields/fields.vue"),
    meta: {
      single: t('hql'),
      endPoint: "Field",
      breadcrumbs: [{ text: t('alhqwl'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin", "Manager"],
    },
  },
  {
    path: "/workflow",
    name: t('almkhttat'),
    component: () => import("../../views/workflow/workflow.vue"),
    meta: {
      single: t('mkhtt'),
      endPoint: "WorkFlow",
      breadcrumbs: [{ text: t('almkhttat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin", "Manager"],
    },
  },
  {
    path: "/workflow/:id",
    name: t('tfasyl-almkhtt'),
    component: () => import("../../views/workflow/detials.vue"),
    meta: {
      single: t('mkhtt'),
      endPoint: "WorkFlow",
      breadcrumbs: [{ text: t('almkhttat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin", "Manager"],
    },
  },
];
