import global from "../../store/global";

var i18n = require("../../i18n").default;
let t = (key) => i18n.t(key);
export default [
  {
    path: "/emails",
    name: i18n.t('almraslat'),
    component: () => import("../../views/emails/emails.vue"),
    meta: {
      single: t('mraslh'),
      endPoint: "Email",
      breadcrumbs: [{ text: t('almraslat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","Employee", "Benefit", "Security","Gate", "Manager"],
    },
  },
  {
    path: "/email/:id",
    name: t('altfasyl'),
    component: () => import("../../views/users/shared/users.vue"),
    meta: {
      single:  t('mraslh'),
      endPoint: "Email",
      breadcrumbs: [{ text: t('almraslat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","Employee", "Benefit", "Security","Gate", "Manager"],
    },
  },
  {
    path: "/emails/add",
    name: "اضافة",
    component: () => import("../../views/emails/add.vue"),
    meta: {
      single:  t('mraslh'),
      endPoint: "Email",
      breadcrumbs: [{ text: t('almraslat'), disabled: true }],
      requireAuth: true,
      role: global.state.roles.Admin,
      roleName: "Admin",
      auth: ["Admin","Employee", "Benefit", "Security","Gate", "Manager"],
    },
  },
];
