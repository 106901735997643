const numeral = require('numeral');
const moment = require('moment');

export const formatNumber = (x) => {
  return numeral(x).format('0,0[.]0');
};
export const formatDate = (date, showTime = true, short = false) => {
  showTime;
  var formate = 'YYYY/MM/DD';
  if (showTime) {
    formate = 'YYYY/MM/DD hh:mmA';
  }
  if(short) {
    formate = 'MM/DD';
  }
  var ret = moment(date).format(formate);
  return ret;
};
export const image = (url) => {
  return "http://158.247.235.172:1337" + url;
}
export const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("files", file);
    fetch("http://158.247.235.172:1337/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const url = "http://158.247.235.172:1337";
export default {
    formatNumber,
    formatDate,
    uploadFile,
    url,
    image
  }
