<template>
  <v-app>
    <div class="area">
      <v-row justify="center" align="center" style="height: 100%">
        <v-col class="" cols="12">
          <!-- <v-avatar size="100">
                    <v-img src="@/assets/images/system logo.jpg"></v-img>
                </v-avatar> -->
          <v-divider vertical="" inset="" class="mx-2" />

          <v-card outlined class="mx-auto r" width="800px" style="z-index: 2">
            <v-container>
              <div class="text-center">
                <v-avatar size="200" rounded="">
                  <v-container>
                    <v-img :src="$store.state.currentLogo" />
                  </v-container>
                </v-avatar>
                <h2 class="mt-2" style="color: #122940">
                  {{ $store.state.config.title }}
                </h2>
                <!-- <h2 class="mt-2" style="color: #122940">مجلس النواب العراقي</h2> -->
                <h2 class="mt-2" style="color: #122940">
                  منظومة سير العمل الإداري
                </h2>
                <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
              </div>
              <br />

              <v-form @submit.prevent="submit" ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("alism-althlathy") }}<span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="user.fullName"
                      :placeholder="$t('alism-althlathy')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("username") }}
                      <span class="required">*</span>
                    </h3>
                    <v-text-field
                      :hint="$t('english-only')"
                      v-model="user.userName"
                      :placeholder="$t('username')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("password") }}<span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="user.password"
                      dir="ltr"
                      :placeholder="$t('password')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">{{ $t("gov") }}</h3>
                    <v-autocomplete
                      v-model="user.provinceId"
                      :items="$global.state.provinces"
                      item-text="name"
                      item-value="id"
                      :label="$t('gov')"
                      hide-details="auto"
                      outlined
                      filled
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">{{ $t("gender") }}</h3>
                    <v-autocomplete
                      v-model="user.gender"
                      :items="gender"
                      item-text="name"
                      item-value="id"
                      :label="$t('gender')"
                      hide-details="auto"
                      outlined
                      filled
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-btn
                  class="btn mt-4"
                  :loading="loading"
                  type="submit"
                  color="primary"
                  x-large
                  block
                >
                  {{ $t("login") }}
                </v-btn>
              </v-form>
            </v-container>
          </v-card>
        </v-col>

        <v-col>
          <v-row justify="center">
            <span style="color: #7c7c7c">
              {{ $t("tm-alttwyr-bwasth") }}
              <a href="https://www.google.com/"
                >
                {{$store.state.config.company}}</a
              >
              |
              {{ new Date().getFullYear() }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import validations from "@/utils/validations";
export default {
  components: {
    // loginSupport: () => import('../../assets/animation/loginSupport.vue'),
  },

  data() {
    return {
      user: {},
      loading: false,
      valid: true,
      showPassword: false,
      ...validations,
      gender: [
        {
          name: this.$t("male"),
          id: true,
        },
        {
          name: this.$t("female"),
          id: false,
        },
      ],
    };
  },

  created() {
    this.user = {
      fullName: "",
      userName: "",
      // email: null,
      password: "",
      // phoneNumber: null,
      imageUrl: "",
      address: null,
      jobTitle: "",
      provinceId: "24e45ff2-e357-4ff3-b2d9-755099c6986c",
      organizationId: "0cc7ff4b-eb0e-4f51-81b6-ce3f983c738a",
      roleName: "Guest",
      userGroups: [
        {
          groupId: "f536d3bc-506b-4eb2-9dfc-0748dd6d3ce1",
        },
      ],
      groups: [],
      gender: true,
      birthday: "1999-01-01",
      areaId: null,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        this.birthday = "2022-07-12T17:58:51.294Z";
        const res = await this.$http.post("/user/guest", this.user);
        await sessionStorage.setItem("token", res.data.result.token);

        await sessionStorage.setItem("user", JSON.stringify(res.data.result));
        await this.$store.dispatch("login");

        switch (res.data.result.role) {
          case "Employee":
            await this.$router.push("/orders");
            break;

          case "Benefit":
            await this.$router.push("/permit");
            break;

          case "Security":
            await this.$router.push("/permit");
            break;

          case "Gate":
            await this.$router.push("/gate");
            break;

          case "Admin":
            await this.$router.push("/users");
            break;

          case "User Managmenet":
            await this.$router.push("/users");
            break;
          case "Guest":
            await this.$router.push("/guest");
            break;
          case "User":
            await this.$router.push("/orderDataChild");
            break;
          default:
            await this.$router.push("/users");
            break;
        }
      } catch (err) {
        this.$service.faild(this.$t("login-error"));
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
  },
};
</script>

<style>
.v-text-field__slot {
  /* padding: 15px !important; */
}
</style>
<style lang="scss">
// @import url('https://fonts.googleapis.com/css?family=Exo:400,700');

// * {
//   margin: 0px;
//   padding: 0px;
// }

// body {
//     font-family: 'Exo', sans-serif;
// }

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: #ffffff;
  background-size: 50% auto;
  background: white;
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

#background {
  position: fixed;
  top: 0;
  // left: 0;
  width: 50%;
  height: 100%;
  background-color: #4460a3;
}

#content {
  position: relative;
  z-index: 2;
  padding: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
